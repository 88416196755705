import React from 'react'
import {Link} from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

const CategoryItemTopLevel = ( { parentUrl, classes, category: { name, slug, categoryImage: { headerBackground } } }) => (
  <BackgroundImage 
    tag="div" 
    className={`category-first w-full md:w-1/2 flex flex-wrap items-center justify-center px-2 ${classes}`}
    fluid={headerBackground.localFile.childImageSharp.fluid} 
  >  
    <Link className="no-underline" to={`/products${parentUrl ? '/' + parentUrl + '/' : '/'}${slug}/`}>
      <div className="category-first__overlay absolute top-0 left-0 w-full h-full" />
      <div className="relative">
        {name && <h2 className="mb-0 text-white text-2xl">{name}</h2>}
        {name && <span className="category-first__subtitle absolute mb-0 text-white block">View Products</span>}
      </div>
    </Link>
  </BackgroundImage>
)

export default CategoryItemTopLevel