import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import {graphql, StaticQuery} from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Emailer from '../components/sections/Emailer'
import CategoryItemTopLevel from '../components/categories/Category-item-top-level';

export default ({data}) => {
    return (
        <Layout>
            <SEO title="Products"/>
                <StaticQuery query={query} render={props => <div className="flex flex-wrap mb-16 md:mb-24">
                        {props.allWpCategory.edges.filter(category => 
                            category.node.parentDatabaseId === null
                        )
                        .map(category => (
                            <CategoryItemTopLevel key={uuidv4()} classes="category-first--large" category={category.node}/>
                        ))}
                        {
                        props.allContentfulCategories.nodes.map(category => {
                            return {...category, categoryImage: {headerBackground: {
                                ...category.image
                            }}}
                        })
                        .map(category => (
                            <CategoryItemTopLevel key={uuidv4()} classes="category-first--large" category={category}/>
                        ))}
                    </div>
                } />
            <div className="mx-auto container">
                {/* <h2>Additional Products</h2> */}
                <Emailer />
            </div>
        </Layout>
    )
}


const query = graphql`
{
    allWpCategory(filter: {termTaxonomyId: {ne: 1}}) {
        edges {
            node {
                slug
                termTaxonomyId
                parentDatabaseId
                name
                slug
                categoryImage {
                    headerBackground {
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 1000, quality: 50) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    allContentfulCategories(filter: {isParent: {eq: true}}) {
        nodes {
          slug
          name
          image {
            localFile {
                childImageSharp {
                    fluid(maxWidth: 1000, quality: 50) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
          }
        }
    }
}
`